<script lang="ts">
  import Button from '../../shared/Button.svelte'
  import ModalQcmImport from '../ModalQCMImport.svelte'
  import { showModal } from '../../../lib/ts/services/modal'
  import Qcm from '../../../lib/ts/models/qcm'
  import { qcms } from '../../../lib/ts/services/store'
  import { displayQcmEditor, textToQuestionWidthFactor } from '../../../lib/ts/services/qcm'
  import utils from '../../../lib/ts/services/utils'
  import { onMount } from 'svelte'
  import { renderLatex } from '../../../lib/ts/services/katex'
  // import type Question from '../../../lib/ts/models/question'
  import QcmSelectionCard from './QCMSelectionCard.svelte'
  import QcmSelectionLine from './QCMSelectionLine.svelte'
  import QcmList from '../../../lib/ts/models/qcmsList';

  export let selectedQcmId: number

  let isCardList = true
  let isAlphabeticallySorted = false

  onMount(
    async () => {
      // todo: optimiser le chargement de la liste des qcms qui ne doit
      // se produire qu'une fois que qcm a chargé toutes ses questions
      if ($qcms.list.length === 0) {
        setTimeout(() => {listQcms()}, 250)
      } else {
        listQcms()
      }
  })

  function listQcms () {
    $qcms.getMiniatures()
      .then(() => {
        $qcms = $qcms
        // todo: optimiser le chargement de la liste des qcms qui ne doit
        // se produire qu'une fois que les miniatures se sont chargées
        setTimeout(() => {renderLatex('section-qcms-0')},250)
        setQCMCardsFontSize()
      })
  }

  async function addQcm () {
    const qcm = new Qcm('Titre', 'qcmcam2')
    qcm.init()
    const question = await qcm.createQuestion('<h3>Question 1</h3>')
    question.addAnwser('')
    question.addAnwser('')
    await question.save()
    await qcm.save()
    // id du qcm, nom du qcm, id de la première question, nb de questions du qcm
    $qcms.list.push([qcm.id, qcm.name, qcm.questionsList[0], 1])
    selectedQcmId = $qcms.list.length - 1
    displayQcmEditor()
  }

  function downloadQcms () {
    const selection = document.querySelectorAll('#qcms-list .check-selection:checked')
    if (selection.length === 0) {
      $qcms.export()
      .then(blob => {
        utils.file.download(blob, 'qcmcam-liste-des-qcm.txt')
      })
      .catch(err => {
        console.log(err)
      })      
    } else {
      const lesQcms = new QcmList()
      for (const element of Array.from(selection)) {
        lesQcms.list.push($qcms.list.filter(o => o[0] === parseInt((element as HTMLInputElement).value))[0])
      }
      lesQcms.export()
        .then(blob => {
          utils.file.download(blob, 'qcmcam-liste-des-qcm.txt')
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  async function deleteQcms () {
    const selection = document.querySelectorAll('#qcms-list .check-selection:checked')
    if(selection.length === 0) {
      if (confirm('Voulez-vous supprimer tous les qcms ?')) {
        for (const qcm of $qcms.list) {
          await $qcms.deleteQcmById(qcm[0])
        }
        listQcms()
      }
    } else {
      if (confirm('Voulez-vous supprimer les qcms sélectionnés ?')) {
        for (const element of Array.from(selection)) {
          await $qcms.deleteQcmById(parseInt((element as HTMLInputElement).value))
        }
        listQcms()
        document.querySelectorAll('#qcms-list .check-selection:checked').forEach(element => {
          (element as HTMLInputElement).checked = false
        })
      }
    }
  }

  function setQCMCardsFontSize (): void {
    const questionContainers = document.querySelectorAll<HTMLElement>('#qcms-list .question-container')
    for (const questionContainer of Array.from(questionContainers)) {
      const width = questionContainer.getBoundingClientRect().width
      const pixelSize = width / textToQuestionWidthFactor
      questionContainer.style.fontSize = String(pixelSize) + 'px'
      utils.image.defaultPixelSize = pixelSize
    }
  }

  function updateSelectedQcmId (id: number): void {
    selectedQcmId = id
  }

  function sortQcms (): void {
    const iconSort = document.getElementById('icon-sort')
    if (iconSort === null) return
    if (isAlphabeticallySorted) {
      $qcms.list.sort((a, b) => a[1].localeCompare(b[1]))
      iconSort.classList.remove('ri-sort-alphabet-asc')
      iconSort.classList.add('ri-sort-number-asc')
    } else {
      $qcms.list.sort((a, b) => a[0] - b[0])
      iconSort.classList.remove('ri-sort-number-asc')
      iconSort.classList.add('ri-sort-alphabet-asc')
    }
    listQcms()
  }
  
</script>

<div class="tab">
  <nav class="navbar">
    <div class="navbar-menu">
      <div class="navbar-brand">
        <div class="navbar-item"> Commandes :</div>
      </div>
      <div class="navbar-start">
        <Button on:click={addQcm}>
          <i class="ri-play-list-add-line"></i>
          Créer un qcm
        </Button>
        <Button on:click={() => showModal('qm-import-qcm')}>
          <i class="ri-file-upload-line"></i>
          Importer des qcm
        </Button>
        <Button on:click={downloadQcms}>
          <i class="ri-file-download-line"></i>
          Télécharger
        </Button>
        <Button on:click={deleteQcms}>
          <i class="ri-delete-bin-line"></i>
          Supprimer
        </Button>
        <!-- <a id="btn-save-qcms"><i class="ri-save-line"></i> Enregistrer</a>-->
      </div>
    </div>
  </nav>
  <div id="qcms-list">
    <div class="flex flex-row border-b">
      <ul class="flex flex-row items-center">
        <li class="h-12 px-2 border-b border-transparent hover:border-black transition-colors duration-300 flex items-center"><button class="h-12">Mes questionnaires</button></li>
        <li class="h-12 px-2 border-b border-transparent hover:border-black transition-colors duration-300 flex items-center">
          <button class="h-12" title="Tri des QCM" on:click={() => { isAlphabeticallySorted = !isAlphabeticallySorted; sortQcms() }}><i id="icon-sort" class="ri-sort-alphabet-asc"></i></button>
        </li>
        <li class="h-12 px-2 border-b border-transparent hover:border-black transition-colors duration-300 flex items-center"><button class="h-12" title="Affichage des QCM" on:click={() => { isCardList = !isCardList }}><i class="ri-list-settings-line"></i></button></li>
        <li class="h-12 px-2 border-b border-transparent hover:border-black transition-colors duration-300 flex items-center"><button class="h-12" title="Ajouter une section"><i class="ri-add-line"></i></button></li>
      </ul>
    </div>
    <section id="section-qcms-0">
      {#if isCardList}
        {#each $qcms.list.keys() as index}
          <QcmSelectionCard
            qcms={$qcms}
            {index}
            {listQcms}
            {updateSelectedQcmId}
            {displayQcmEditor}
          />
        {/each}
      {:else}
        {#each $qcms.list as question, index}
          <QcmSelectionLine
            qcms={$qcms}
            {index}
            {listQcms}
            {updateSelectedQcmId}
            {displayQcmEditor}
          />
        {/each}
      {/if}
    </section>
  </div>
</div>
<ModalQcmImport
  {listQcms}
  bind:selectedQcmId={selectedQcmId}
/>
