<script lang="ts">
  import { CONFIG_DEFAULT_COLORS } from '../../lib/ts/models/config'
  import { updateColors } from '../../lib/ts/services/colors'
  import { hideModal } from '../../lib/ts/services/modal'
  import { saveSettings } from '../../lib/ts/services/settings'
  import { config } from '../../lib/ts/services/store'

  let modal: HTMLElement

  function handleColorChange (index: number, event: Event) {
    const target = event.target
    if (target === null) throw new Error('Unexpected target')
    if (!(target instanceof HTMLInputElement)) throw new Error('Unexpected target')
    const color = target.value
    setDefaultColor(index, color)
  }

  function handleDefaultTimeInput () {
    const input = document.getElementById('defaultTime') as HTMLInputElement
    setDefaultSlideTime(input.value)
  }
  function setDefaultColor (index: number, colorValue: string) {
    $config.defaultColors[index - 1] = colorValue
    updateColors()
    saveSettings()
  }
  function resetDefaultColors () {
    $config.defaultColors = [...CONFIG_DEFAULT_COLORS]
    saveSettings()
    updateColors()
  }
  function setDefaultSlideTime (time: string) {
    $config.defaultSlideTime = Number(time)
    saveSettings()
  }
  function changeOpinion (opinionOption: string) {
    $config.changeOpinion = opinionOption === 'yes'
    saveSettings()
  }
  function changeAutoStopOption (autoStopOption: string) {
    $config.scanAutoStop = autoStopOption === 'yes'
    saveSettings()
  }
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="modal" id="qm-settings" bind:this={modal}>
  <div class="modal-background" on:click|self={() => hideModal(modal)}></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Réglages</p>
      <button class="delete closebutton" aria-label="close" data-modal="qm-settings" on:click={() => hideModal(modal)}></button>
    </header>
    <div class="modal-card-body">
      <div>Vous utilisez actuellement <span id="storagesize"></span> % du stockage disponible sur votre ordinateur.</div>
      <div class="is-title">Changement d'avis</div>
      <div>
        <input type="radio" name="changevote" id="changeopinionyes" class="is-checkradio" value="yes" checked on:click={() => { changeOpinion('yes') }}>
        <label for="changeopinionyes">Un participant <b>peut</b> changer son vote</label>  
      </div>
      <div>
        <input type="radio" name="changevote" id="changeopinionno" class="is-checkradio" value="no" on:click={() => { changeOpinion('no') }}>
        <label for="changeopinionno">Un participant <b>ne peut pas</b> changer son vote</label>
      </div>
      <div class="is-title">Arrêt du scan</div>
      <div>
        <input type="radio" name="stopscan" id="stopscanyes" value="yes" class="is-checkradio is-info" checked on:click={() => { changeAutoStopOption('yes') }}>
        <label for="stopscanyes">Le scan par webcam <b>cesse</b> automatiquement 5 s. après le dernier vote.</label>  
      </div>
      <div>
        <input type="radio" name="stopscan" id="stopscanno" class="is-checkradio is-info" value="no" on:click={() => { changeAutoStopOption('no') }}>
        <label for="stopscanno">Le scan par webcam doit être <b>arrêté manuellement</b>.</label>  
      </div>
      <br>
      <div class="field is-horizontal">
        <div class="is-normal">
          Temps d'<b>affichage des questions</b> par défaut
        </div>
        <div class="field mx-2 valign-middle">
          <p class="control has-icons-left has-icons-right">
            <input type="number" class="input has-text-right" min="0" max="200" title="0 = illimité" value="15" on:input={handleDefaultTimeInput}>
            <span class="icon is-left"><i class="ri-timer-line"></i></span>
            <span class="icon is-right">s.</span>
          </p>
        </div>
      </div>
      <div class="is-title">Message d'introduction du diaporama</div>
      <input type="text" class="input is-link" id="messageFisrtSlide" placeholder="Texte d'introduction">
      <div class="is-title">Couleurs des questions par défaut</div>
      <div class="field is-horizontal" id="defaultColors">
        <label for="defaultColor0" class="field-label">Réinitialiser</label>
        <button class="button" id="defaultColor0" on:click={resetDefaultColors}>Défaut</button>
        <label for="defaultColor1" class="field-label">A</label>
        <input type="color" id="defaultColor1" class="input is-link" value="#a8f9f9" on:change={(event) => handleColorChange(1, event)}>
        <label for="defaultColor2" class="field-label">B</label>
        <input type="color" id="defaultColor2" class="input is-link" value="#f9b7d8" on:change={(event) => handleColorChange(2, event)}>
        <label for="defaultColor3" class="field-label">C</label>
        <input type="color" id="defaultColor3" class="input is-link" value="#c5bcf8" on:change={(event) => handleColorChange(3, event)}>
        <label for="defaultColor4" class="field-label">D</label>
        <input type="color" id="defaultColor4" class="input is-link" value="#f8f596" on:change={(event) => handleColorChange(4, event)}>
      </div>
    </div>
  </div>
</div>

<style>
  .is-title {
    font-weight: bold;
    margin-top: 0.6em;
    margin-bottom: 0.2em;
  }
  .is-horizontal {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
</style>